@import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif&display=swap');

/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #74756A #DFE9EB;
}

/* Chrome, Edge and Safari */
*::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

*::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #DFE9EB;
}

*::-webkit-scrollbar-track:hover {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-track:active {
    background-color: #B8C0C2;
}

*::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #74756A;
}

*::-webkit-scrollbar-thumb:hover {
    background-color: #FE8403;
}

*::-webkit-scrollbar-thumb:active {
    background-color: rgb(254, 132, 3);
}